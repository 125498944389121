import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation

const LoginRegister = () => {
  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/api/auto-login",
          {
            method: "GET",
            credentials: "include", // Include cookies in the request
          }
        );

        if (response.ok) {
          // If the user is authenticated, redirect to the game page
          navigate("/game");
        }
        // If not authenticated, do nothing (let the user see the login page)
      } catch (error) {
        console.error("Failed to check login status:", error);
      }
    };

    checkLoginStatus();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ username, password }),
        }
      );

      if (response.ok) {
        navigate("/game");
      } else {
        const data = await response.json();
        setErrorMessage(data.message || "Login failed");
      }
    } catch (error) {
      setErrorMessage("Login failed");
      console.error("Login error:", error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
            bankName,
            accountNumber,
            accountName,
          }),
        }
      );

      if (response.ok) {
        navigate("/game");
      } else {
        const data = await response.json();
        setErrorMessage(data.message || "Registration failed");
      }
    } catch (error) {
      setErrorMessage("Registration failed");
      console.error("Registration error:", error);
    }
  };

  const handleToggle = () => {
    setIsRegister(!isRegister);
    setErrorMessage("");
  };

  return (
    <div className="min-h-screen bg-login flex flex-col">
      <header className="bg-pink-700 text-white py-4 shadow-lg">
        <div className="container mx-auto px-4">
          <h1 className="text-xl font-bold">Realslot456</h1>
        </div>
      </header>
      <div className="flex-grow flex items-center justify-center">
        <div className="bg-white p-20 rounded-lg shadow-lg w-full max-w-md">
          {isRegister ? (
            <>
              <h1 className="text-2xl font-bold mb-6 text-center">Register</h1>
              <form onSubmit={handleRegister}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg"
                  required
                />
                <input
                  type="text"
                  placeholder="Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg"
                  required
                />
                <input
                  type="text"
                  placeholder="Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg"
                  required
                />
                <input
                  type="text"
                  placeholder="Account Name"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                  className="mb-6 w-full px-4 py-2 border rounded-lg"
                  required
                />
                {errorMessage && (
                  <p className="text-red-500 text-center mb-4">
                    {errorMessage}
                  </p>
                )}
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white py-4 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                >
                  Register
                </button>
              </form>
              <p className="mt-4 text-center">
                Already have an account?{" "}
                <button
                  onClick={handleToggle}
                  className="text-blue-500 hover:underline focus:outline-none"
                >
                  Login here
                </button>
              </p>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-bold mb-6 text-center">Login</h1>
              <form onSubmit={handleLogin}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-6 w-full px-4 py-2 border rounded-lg"
                  required
                />
                {errorMessage && (
                  <p className="text-red-500 text-center mb-4">
                    {errorMessage}
                  </p>
                )}
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white py-4 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                >
                  Login
                </button>
              </form>
              <p className="mt-4 text-center">or</p>
              <button
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL + "/api/login")
                }
                className="mt-4 w-full bg-blue-500 text-white py-4 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Login with LINE
              </button>
              <p className="mt-4 text-center">
                Don't have an account?{" "}
                <button
                  onClick={handleToggle}
                  className="text-blue-500 hover:underline focus:outline-none"
                >
                  Register here
                </button>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;
