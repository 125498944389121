import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Game from "./components/Game";
import Register from "./components/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import Deposit from "./components/Deposit";
import Withdraw from "./components/Withdraw";
import Payment from "./components/Payment";
import Paymentstatus from "./components/Paymentstatus";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedRoute requiredRole="member" />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/game" element={<Game />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/payment/:id" element={<Payment />} />
          <Route path="/paymentstatus" element={<Paymentstatus />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/not-authorized" element={<div>Not Authorized</div>} />
      </Routes>
    </Router>
  );
};

export default App;
