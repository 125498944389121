import React, { useState, useEffect, useRef } from "react";
import "./styles.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import slotImg from "../slot.gif";

const bankOptions = [
  { value: "002", label: "ธนาคารกรุงเทพ (BBL)", logo: "path/to/bbl-logo.png" },
  {
    value: "004",
    label: "ธนาคารกสิกรไทย (KBANK)",
    logo: "path/to/kbank-logo.png",
  },
  { value: "006", label: "ธนาคารกรุงไทย (KTB)", logo: "path/to/ktb-logo.png" },
  {
    value: "008",
    label: "JPMorgan Chase (JPMC)",
    logo: "path/to/jpmc-logo.png",
  },
  {
    value: "011",
    label: "ธนาคารทหารไทยธนชาต (TTB)",
    logo: "path/to/ttb-logo.png",
  },
  {
    value: "011",
    label: "ธนาคารทหารไทยธนชาต (TMB)",
    logo: "path/to/ttb-logo.png",
  },
  {
    value: "014",
    label: "ธนาคารไทยพาณิชย์ (SCB)",
    logo: "path/to/scb-logo.png",
  },
  { value: "017", label: "Citibank (CITI)", logo: "path/to/citi-logo.png" },
  {
    value: "018",
    label: "Sumitomo Mitsui Banking Corporation (SMBC)",
    logo: "path/to/smbc-logo.png",
  },
  {
    value: "020",
    label: "Standard Chartered Bank (SCBT)",
    logo: "path/to/scbt-logo.png",
  },
  {
    value: "022",
    label: "ธนาคารซีไอเอ็มบีไทย (CIMBT)",
    logo: "path/to/cimbt-logo.png",
  },
  {
    value: "023",
    label: "ธนาคารอาร์เอชบี (RHB)",
    logo: "path/to/rhb-logo.png",
  },
  { value: "024", label: "ธนาคารยูโอบี (UOBT)", logo: "path/to/uobt-logo.png" },
  {
    value: "025",
    label: "ธนาคารกรุงศรีอยุธยา (BAY)",
    logo: "path/to/bay-logo.png",
  },
  { value: "026", label: "ธนาคารเมกะ (MEGA)", logo: "path/to/mega-logo.png" },
  {
    value: "027",
    label: "Bank of America (AMERICA)",
    logo: "path/to/america-logo.png",
  },
  {
    value: "028",
    label: "ธนาคารจีนอุตสาหกรรมและการพาณิชย์ (CAIB)",
    logo: "path/to/caib-logo.png",
  },
  { value: "029", label: "ธนาคารอินเดีย (IOB)", logo: "path/to/iob-logo.png" },
  { value: "030", label: "ธนาคารออมสิน (GSB)", logo: "path/to/gsb-logo.png" },
  { value: "031", label: "HSBC (HSBC)", logo: "path/to/hsbc-logo.png" },
  {
    value: "033",
    label: "ธนาคารอาคารสงเคราะห์ (GHB)",
    logo: "path/to/ghb-logo.png",
  },
  {
    value: "034",
    label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (BAAC)",
    logo: "path/to/baac-logo.png",
  },
  {
    value: "035",
    label: "ธนาคารส่งออกและนำเข้าแห่งประเทศไทย (EXIM)",
    logo: "path/to/exim-logo.png",
  },
  { value: "039", label: "Mizuho Bank (MHCB)", logo: "path/to/mhcb-logo.png" },
  { value: "045", label: "BNP Paribas (BNPP)", logo: "path/to/bnpp-logo.png" },
  { value: "052", label: "Bank of China (BOC)", logo: "path/to/boc-logo.png" },
  {
    value: "066",
    label: "ธนาคารอิสลามแห่งประเทศไทย (ISBT)",
    logo: "path/to/isbt-logo.png",
  },
  {
    value: "067",
    label: "ธนาคารทิสโก้ (TISCO)",
    logo: "path/to/tisco-logo.png",
  },
  {
    value: "069",
    label: "ธนาคารเกียรตินาคินภัทร (KKP)",
    logo: "path/to/kkp-logo.png",
  },
  {
    value: "070",
    label: "Industrial and Commercial Bank of China (ICBCT)",
    logo: "path/to/icbct-logo.png",
  },
  {
    value: "071",
    label: "ธนาคารไทยเครดิต (TCD)",
    logo: "path/to/tcd-logo.png",
  },
  {
    value: "073",
    label: "ธนาคารแลนด์แอนด์เฮ้าส์ (LHFG)",
    logo: "path/to/lhfg-logo.png",
  },
  {
    value: "080",
    label: "ธนาคารซูมิโตโม มิตซุย (SMTB)",
    logo: "path/to/smtb-logo.png",
  },
  {
    value: "098",
    label: "ธนาคารเอสเอ็มอี (SME)",
    logo: "path/to/sme-logo.png",
  },
  { value: "901", label: "KTC (KTC)", logo: "path/to/ktc-logo.png" },
  { value: "907", label: "AEON (AEON)", logo: "path/to/aeon-logo.png" },
];

const Withdraw = () => {
  //   const [bankCode, setBankCode] = useState("");
  //   const [accountNumber, setAccountNumber] = useState("");
  //   const [accountName, setAccountName] = useState("");
  //   const [phoneNumber, setPhoneNumber] = useState("");
  //   const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/profile",
          {
            withCredentials: true,
          }
        );
        console.log("User details fetched:", response.data);
        //setPlayerId(response.data._id);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to your backend API
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/requestwithdraw",
        { amount: withdrawAmount },
        { withCredentials: true } // Ensure credentials are sent with the request
      );

      const { id } = response.data; // Extract ID from response
      console.log("Deposit request ID:", id);

      // Redirect to the payment page with the deposit request ID
      navigate(`/paymentstatus`);
    } catch (error) {
      console.error("Error submitting deposit request:", error);
      alert("Failed to submit deposit request. Please try again.");
    }
  };

  return (
    <div className="flex items-center bg-login justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md text-center">
        <h1 className="text-2xl font-bold">
          {/* <img src={slotImg} width="50px" alt="Slot" /> */}
          ถอนเงิน
        </h1>
        <span>ระบุจำนวนเงินที่ต้องการถอน</span>
        <form onSubmit={handleSubmit}>
          <input
            type="number"
            className="w-full borderborder-gray-300 rounded-lg p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-600 mt-5"
            placeholder="100.00"
            onChange={(e) => {
              setWithdrawAmount(e.target.value);
            }}
          />

          <button
            type="submit"
            className="w-full bg-blue-500 text-white mt-6 mb-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            ถอนเงิน
          </button>
        </form>
      </div>
    </div>
  );
};

export default Withdraw;
