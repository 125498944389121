import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css"; // Ensure you have your custom styles here
import jerryImage from "../jerryplay.png";
import ppImage from "../pp.jpeg";
import pgImage from "../pg.png";
import jokerImage from "../joker.png";
import huaydragon from "../huaydragon.png";
import { wait } from "@testing-library/user-event/dist/utils";
import lalika from "../lalikabet.png";

const Game = () => {
  const [playerId, setPlayerId] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/profile",
          {
            withCredentials: true,
          }
        );
        console.log("User details fetched:", response.data);
        setPlayerId(response.data._id);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleGameClick = async (vendor) => {
    console.log("Clicked vendor:", vendor);
    if (!playerId) {
      console.error("Player ID is not available");
      return;
    }
    if (vendor === "lalika") {
      console.log("Launching lalika game");
      const lalikaParams = { playerId };
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/lalika/launch",
          lalikaParams,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        console.log("API response:", response.data);
        const gameUrl = response.data.luanchUrl;
        if (gameUrl) {
          window.location.href = gameUrl; // Redirect directly to the game URL for lalika
          return; // Ensure no further code execution
        } else {
          console.error("gameUrl not found in response for lalika");
        }
      } catch (error) {
        console.error("Error fetching game URL for lalika:", error);
      }

      return;
    }

    if (vendor === "huaydragon") {
      const huaydragonParams = { playerId };
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/huaydragon/launch",
          huaydragonParams,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        console.log("API response:", response.data);
        const gameUrl = response.data.data.url;
        if (gameUrl) {
          window.location.href = gameUrl; // Redirect directly to the game URL for huaydragon
          return; // Ensure no further code execution
        } else {
          console.error("gameUrl not found in response for huaydragon");
        }
      } catch (error) {
        console.error("Error fetching game URL for huaydragon:", error);
      }
      return; // Return early after handling huaydragon
    }

    // Handle other vendors
    const gameId = vendor === "jocker" ? "584" : "113";
    const params = {
      playerId,
      gameId,
      casinoId: process.env.REACT_APP_GAMEAPICASINOID,
    };

    if (vendor !== "jocker") {
      params["gameConfig.vendor"] = vendor;
    }

    try {
      console.log("Request params:", params);
      const response = await axios.post(
        process.env.REACT_APP_GAMEAPIURL,
        null,
        {
          params,
          headers: {
            "hg-agent-user": process.env.REACT_APP_GAMEAPIUSER,
            "hg-agent-password": process.env.REACT_APP_GAMEAPIPASSWORD,
          },
        }
      );

      console.log("API response:", response.data);
      const gameUrl = response.data.response.gameUrl;
      if (gameUrl) {
        window.location.href = gameUrl; // Redirect to the game URL for other vendors
      } else {
        console.error("gameUrl not found in response for vendor:", vendor);
      }
    } catch (error) {
      console.error("Error fetching game URL for vendor:", vendor, error);
    }
  };

  const handleNavigation = (path) => {
    window.location.href = path;
  };

  const handleLogout = async () => {
    try {
      await axios.get(process.env.REACT_APP_API_URL + "/api/logout", {
        withCredentials: true,
      });
      window.location.href = "/"; // Redirect to the login page
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className="min-h-screen bg-login flex flex-col">
      <header className="bg-pink-700 text-white py-4 shadow-lg">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <h1 className="text-xl font-bold">Realslot456</h1>
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
          >
            Logout
          </button>
        </div>
      </header>

      <div className="flex-grow flex items-center justify-center opacity-90">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md text-center">
          <div className="flex-grow flex items-center justify-center opacity-90 border-4 border-lime-400 mb-10">
            <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md text-center">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-2">
                <h1 className="font-bold col-span-2 pb-4">
                  <span className="text-s">{user?.displayName || 0}</span>
                  <br />
                  <span className="text-xl">
                    เครดิต : {user?.wallet?.balance || 0} บาท{" "}
                  </span>
                </h1>
                <button
                  onClick={() => handleNavigation("/deposit")}
                  className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
                >
                  ฝาก
                </button>
                <button
                  onClick={() => handleNavigation("/withdraw")}
                  className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
                >
                  ถอน
                </button>
              </div>
            </div>
          </div>
          {/* <h1 className="text-l font-bold mb-8 text-center text-l">
            เลือกค่ายพร้อมปั่นได้เลย
          </h1> */}

          <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-8">
            <img
              src={jerryImage}
              alt="Game 1"
              onClick={() => handleGameClick("jerry")}
              disabled={!playerId}
              className="text-white py-2 px-4 rounded"
            />

            <img
              src={ppImage}
              disabled={!playerId}
              onClick={() => handleGameClick("pragmatic")}
              className="rounded-lg w-full h-full object-cover"
            />
            <img
              src={pgImage}
              onClick={() => handleGameClick("pgsoft")}
              disabled={!playerId}
              className="text-white py-2 px-4 rounded-lg"
            />
            <img
              src={jokerImage}
              style={{ width: "100%" }}
              onClick={() => handleGameClick("jocker")}
              disabled={!playerId}
              className="text-white py-2 px-4 rounded-lg"
            />
            <img
              src={huaydragon}
              style={{ width: "100%" }}
              onClick={() => handleGameClick("huaydragon")}
              disabled={!playerId}
              className="text-white py-2 px-4 rounded-lg"
            />
            <img
              src={lalika}
              style={{ width: "100%" }}
              onClick={() => handleGameClick("lalika")}
              disabled={!playerId}
              className="text-white py-2 px-4 rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
