import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/profile",
          {
            withCredentials: true,
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching profile", error);
        if (error.response && error.response.status === 401) {
          navigate("/"); // Redirect to login page if unauthorized
        }
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await axios.get(process.env.REACT_APP_API_URL + "/api/logout", {
        withCredentials: true,
      });
      navigate("/");
    } catch (error) {
      console.error("Error logging out", error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Hello, {user.displayName}</h1>
      <img src={user.pictureUrl} alt="Profile" />
      <h2>Role: {user.role}</h2>
      <h2>Wallet Balance: ${user.wallet.balance}</h2>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Profile;
